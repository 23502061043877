<template>
	<div id="articleTypeList" class="el-content">
		<a-button class="mb12" @click="editType(0)" v-has="{action:'wxapp_set_article_edit',plat:1}" type="primary">
			<i class="ri-add-line"></i>
			新增分类
		</a-button>
        <a-table row-key="id" :pagination="false" :data-source="info.list" :columns="[
            {title:'ID',dataIndex:'id'},
            {title:'名称',dataIndex:'category_name'},
            {title:'是否显示',dataIndex:'is_default',slots:{customRender:'is_default'}},
            {title:'排序',dataIndex:'rank'},
            {title:'最后更新时间',dataIndex:'update_time'},
            {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
        ]" class="mt12">
            <template #is_default="{record}">
                <a-tag color="#00CC66" v-if="record.is_default ==1 ">显示</a-tag>
                <a-tag color="#999" v-if="record.is_default ==0 ">隐藏</a-tag>
            </template>
            <template #action="{record}">
                <a-space>
                    <kd-button type="primary" title="编辑" icon="ri-edit-line" v-has="{action:'wxapp_set_article_edit'}"  @click="editType(record)"></kd-button>
                    <kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_set_article_type_del'}"  @click="deleteType(record.id)"></kd-button>
                </a-space>
            </template>
        </a-table>
        <div class="pager">
            <a-pagination
                show-size-changer
                :default-current="info.page"
                :total="info.count"
                @showSizeChange="(p,e)=>{getArticleType(info.page,e)}"
                @change="(e)=>{getArticleType(e,info.limit)}"
            />
        </div>
        <a-modal v-model:visible="show.edit" title="添加/编辑分类" @ok="saveType" width="600px" @cancel="show.edit = false">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="名称">
					<a-input placeholder="分类名称" v-model:value="form.category_name"></a-input>
				</a-form-item>
				<a-form-item label="排序">
					<a-input v-model:value="form.rank"></a-input>
				</a-form-item>
				<a-form-item label="是否显示">
					<kd-switch :value="form.is_default" @change="e=>form.is_default = e" :inactive-value="0" :active-value="1"></kd-switch>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import artcileModel from "@/api/article.js"
import common from "@/api/common.js"
export default{
	setup(){
		const _d = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
			show:{
				edit:false,
			},
			form:null
		})
		getArticleType(1,_d.info.limit)
		function getArticleType(page,limit){
			artcileModel.getArticleType(page,limit,res=>{
				_d.info.list = res.list
				_d.info.count = res.count
			})
		}
		function editType(row){
			_d.form = {
				id:row ? row.id :0,
				rank:row ? row.rank :99,
				is_default:row ? row.is_default :1,
				category_name:row ? row.category_name :"",
			}
			_d.show.edit = true
		}
		
		function saveType(){
			artcileModel.addOrEditArticleType(_d.form,()=>{
				getArticleType(_d.info.page,_d.info.limit)
				_d.show.edit = false
			})
		}

		const deleteType = (id)=> common.deleteDataList(id,23,'确认删除该资讯分类信息吗？').then(()=>{
			getArticleType(_d.info.page,_d.info.limit)
		})

		return{
			...toRefs(_d),
			editType,
			saveType,
			getArticleType,
			deleteType
		}
	},
}
</script>

<style>
</style>
