import { $post } from '@/api/http.js'
import common from "@/api/common.js"
import tool from '@/util/tool.js'
import router from '../router'
class Article{

    /**
     * 获取资讯分类信息
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getArticleType(page,limit,fn){
        common.getDataList("getArticleCategoryList",{page,limit}).then(res=>{
            fn(res)
        })
    }
    
    /**
     * 新增或编辑资讯分类信息
     * @param {object}} param 
     * @param {function} fn 
     * @returns 
     */
    addOrEditArticleType(param,fn){
        if( !tool.returnMessage(param.category_name,'请输入分类名称')) return
        if( !param.id ) delete param.id 
        $post("createOrUpdateArticleCategory",param).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }
    
    /**
     * 获取资讯列表信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} fn 
     */
    getArticle(page,limit,fn){
        common.getDataList("getArticleList",{page,limit}).then(res=>{
            fn(res)
        })
    }

    /**
     * 新增或编辑资讯信息
     * @param {object} param 
     */
    addOrEditArticle(param){
        if( !tool.returnMessage(param.title,"请填写资讯标题")) return
        if( !tool.returnMessage(param.summary,"请填写资讯副标题")) return
        if( !tool.returnMessage(param.category_id,"请选择资讯分类")) return
        if( !param.id ) delete param.id 
        $post("createOrUpdateArticle",param).then(res=>{
            tool.message("保存成功")
            router.push("/set/article/articleList")
        }).catch(()=>{})
    }

    /**
     * 获取资讯详情并整理所需要的字段
     * @param {number}} id 资讯id
     * @param {Array} keys 需要的字段
     * @param {function} fn 
     */
    getArticleDetail(id,keys=null,fn){
        $post("getArticleDetail",{id}).then(res=>{
            if( keys ){
                fn(tool.filterForm(keys,res.data))
                return
            }
            fn(res.data)
        })
    }
}

const articleModel = new Article()
export default articleModel